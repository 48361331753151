import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

const HeroStyle = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px;
`;

const Links = styled.div`
  max-width: 768px;
  width: 90%;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  a {
    margin-bottom: 32px;
    font-size: 24px;
    text-transform: capitalize;
    color: rgb(207, 0, 0);
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

function Hero() {
  const intl = useIntl();

  return (
    <HeroStyle>
      <Links>
        <Link
          to={`/${intl.formatMessage({
            id: "UrlPrivacy",
          })}/`}
        >
          {intl.formatMessage({
            id: "Privacy",
          })}
        </Link>
        <Link
          to={`/${intl.formatMessage({
            id: "UrlTerms",
          })}/`}
        >
          {intl.formatMessage({
            id: "Terms",
          })}
        </Link>
        <Link
          to={`/${intl.formatMessage({
            id: "UrlLicense",
          })}/`}
        >
          {intl.formatMessage({
            id: "License",
          })}
        </Link>
        <Link
          to={`/${intl.formatMessage({
            id: "UrlCookies",
          })}/`}
        >
          {intl.formatMessage({
            id: "Cookies",
          })}
        </Link>
      </Links>
    </HeroStyle>
  );
}

export default Hero;
