import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/legalities/hero";
import Layout from "../components/shared/layout";

function Legalities() {
  return (
    <Layout>
      <Seo title="Legalities" />
      <Hero />
    </Layout>
  );
}

export default Legalities;
